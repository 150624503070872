import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Page from "../components/page";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query InitiativeTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      publishedAt
      slug {
        current
      }
      _rawBody
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {page && <SEO title={page.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {page && <Page {...page} />}
    </Layout>
  );
};

export default PageTemplate;
