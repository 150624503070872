import React from "react";
import BlockContent from "./block-content";
import Container from "./container";

import * as styles from "./page.module.css";

function Page(props) {
  const { _rawBody } = props;
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
        </div>
      </Container>
    </article>
  );
}

export default Page;
